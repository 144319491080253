import React from "react";
import "./App.css";
import MainStack from "./Stack/MainStack";
import AuthProvider from "./context/AuthContext";

function App() {
  return (
    <>
      <AuthProvider>
        <MainStack />
      </AuthProvider>
    </>
  );
}

export default App;
