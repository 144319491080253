import React from "react";
import { motion } from "framer-motion";
import Footer from "../Home/Section6/Footer";
import Navbar from "../Home/Section1/Navbar";
import Divider from "@mui/material/Divider";
import { AiFillStar } from "react-icons/ai";
import adverstising from "../../assets/img/advertising.png";
import digital from "../../assets/img/social-media.jpg";
import LetsFind from "../Home/Section7/LetsFind";
import { Helmet } from "react-helmet";

export default function SocialMarketing() {
  return (
    <>
      <Helmet>
        <title>Unlock Growth with Our Social Media Service | Call Now!</title>
        <meta
          name="description"
          content="Take your social media to the next level with UDM's social media services. We help you to stand out from the crowd, and to make strong connections."
        />
      </Helmet>

      <Navbar />
      <div className="social-container">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div
            className=""
            style={{ background: "transparent", textAlign: "center" }}
          >
            <h1 className="hero-title">
              <b style={{ color: "#0A3161" }}>Social Media</b>{" "}
              <b style={{ color: "#B31942" }}>Marketing</b>
            </h1>
          </div>
          <div className="container web-description">
            <p>
              At US Digital Marketing, we specialise in creating customised social media
              marketing services to help your business connect with your ideal customers
              and achieve your goals. Our team uses proven techniques to boost your brand
              digitally across social platforms. As a social media marketing agency,
              we help you effectively promote your business on social media with tailored
              strategies designed to engage your target audience.

            </p>
            <Divider>
              <AiFillStar size={20} color="#da1441" alt= "us digital marketing star" />
            </Divider>
          </div>

          <motion.div >
            <div class="card-group my-5">
              <div class="card client-card  ">
                <div class="choose__card">
                  <img src={digital} class="img-fluid rounded-start db_img" alt="us digital marketing service" />
                </div>
                <div class="card-body">
                  <h2>
                    <b>Digital Marketing</b>
                  </h2>
                  <p class="card-text">
                    We offer a wide range of digital marketing services to engage your audience online effectively. Our comprehensive internet marketing strategies help you reach and resonate with customers through various marketing tactics. As a full-service internet marketing agency, we provide diverse advertising and internet marketing services to boost your brand's visibility and growth.
                  </p>
                </div>
              </div>
              <div class="card client-card  ">
                <div class="choose__card">
                  <img
                    src={adverstising}
                    class="img-fluid rounded-start db_img"
                    alt="us digital marketing advertising service" 
                  />
                </div>
                <div class="card-body">
                  <h2>
                    <b>Advertisment</b>
                  </h2>
                  <p class="card-text">
                    Our advertising campaigns are designed to be impactful and compelling, whether through traditional media, digital channels, or both. We create memorable ads tailored to connect with your target audience. As a  renowned agency, we craft customised ads to help your brand stand out. Our digital marketing consultants provide strategic guidance to develop powerful ad campaigns that deliver results.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
          {/* <Divider>
            <AiFillStar size={20} color="#da1441" />
          </Divider> */}

          <div className="db-container" style={{ marginTop: "120px" }}>
            <div
              style={{ background: "transparent", textAlign: "center" }}
            >
              <h2 className="hero-title">
                Our Social Media Service Is The Solution for Every Need
              </h2>
            </div>
            <div className="container web-description">
              <p>
                From small businesses to established reputable brands. We offer social media services.<br />
                Our services are suitable for both your goals and budget.
                <br />
                Choose from:
              </p>
              <Divider>
                <AiFillStar size={20} color="#da1441" alt= "us digital marketing star 1" />
              </Divider>
              <div className="row">
                <div className="col">
                  <div className="card-group mt-5">
                    <div className="card client-card">
                      <div className="choose__card">
                        <AiFillStar color="#da1441"  alt= "us digital marketing star 2"/>
                      </div>
                      <div className="card-body">
                        <p className="db-title">Social Media <br /> Audits</p>
                        <p>
                          First, we assess your current presence and then identify the areas where you need to improve.

                        </p>
                      </div>
                    </div>
                    <div className="card client-card">
                      <div className="choose__card">
                        <AiFillStar color="#da1441"  alt= "us digital marketing star 3"/>
                      </div>
                      <div className="card-body">
                        <p className="db-title">Content <br /> Creation</p>
                        <p>Get engaging posts across different platforms to keep your audience interested and connect with your brand.
                        </p>
                      </div>
                    </div>
                    <div className="card client-card">
                      <div className="choose__card">
                        <AiFillStar color="#da1441"  alt= "us digital marketing star 4"/>
                      </div>
                      <div className="card-body">
                        <p className="db-title">Paid <br /> Advertising</p>
                        <p>
                          Increase your audience reach and target specific groups with target ads.
                        </p>
                      </div>
                    </div>
                    <div className="card client-card">
                      <div className="choose__card">
                        <AiFillStar color="#da1441" alt= "us digital marketing star 5" />
                      </div>
                      <div className="card-body">
                        <p className="db-title">Social Media <br /> Reporting</p>
                        <p>Detailed reports provide valuable insights into your performance and engagement on social media platforms
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetsFind />
      <Footer />
    </>
  );
}
