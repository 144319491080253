import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "../components/Home/Main";
import Talk from "../components/TalkToUs/Talk";
import Story from "../components/OurStory/Story";
import WebDev from "../components/OurServices/WebDev";
import ContentWriting from "../components/OurServices/ContentWriting";
import SocialMarketing from "../components/OurServices/SocialMarketing";
import DbProgramming from "../components/OurServices/DbProgram";
import SEO from "../components/OurServices/SEO";
import Branding from "../components/OurServices/Branding";
import OurBlogs from "../components/Blog/OurBlogs";
import Work from "../components/WorkWithUs/Work";

function MainStack() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/Talk" element={<Talk />} />
          <Route exact path="/about_us" element={<Story />} />
          <Route exact path="/web_development" element={<WebDev />} />
          <Route exact path="/content_writing" element={<ContentWriting />} />
          <Route exact path="/social_marketing" element={<SocialMarketing />} />
          <Route
            exact
            path="/database_programming"
            element={<DbProgramming />}
          />
          <Route exact path="/seo" element={<SEO />} />
          <Route exact path="/branding" element={<Branding />} />
          <Route exact path="/our_blogs" element={<OurBlogs />} />
          <Route exact path="/work_with_us" element={<Work />} />
        </Routes>
      </Router>
    </>
  );
}

export default MainStack;
