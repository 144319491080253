import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import { UserLogin } from "../../context/AuthContext";

export default function GetQuote() {
  const form = useRef();
  const { modalOpen, toggleModal, setModalOpen } = UserLogin();

  // Function to send email
  const handleSubmitForm = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_uubi856",
        "template_mwystka",
        form.current,
        "F3fCl9xjwtltwxCp7"
      )
      .then(
        (result) => {
          setModalOpen(false);
          Swal.fire({
            icon: "success",
            title: "Message Sent!",
            text: "Your message has been received. We will get back to you soon.",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'custom-confirm-button-class',
            },
          });
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while sending your message. Please try again later.",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'custom-confirm-button-class',
            },
          });
        }
      );
  }

  return (
    <>
      <div
        className={`modal fade ${modalOpen ? "show" : ""}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!modalOpen}
        style={{
          display: modalOpen ? "block" : "none",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Request an{" "}
                <span style={{ color: "#da1441" }}>Urgent Quote </span>
              </h1>
              <button
                type="button"
                onClick={toggleModal}
              >X</button>
            </div>
            <div className="modal-body">
              <div classNameName="col py-3">
                <form onSubmit={handleSubmitForm} ref={form}>
                  <div className="mb-3 py-2">
                    <label for="name" className="form-label">
                      Full name *
                    </label>
                    <TextField
                      id="name"
                      type="text"
                      name="name"
                      label="Your Name"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3 py-2">
                    <label for="email" className="form-label">
                      Email address *
                    </label>
                    <TextField
                      id="email"
                      type="email"
                      name="email"
                      label="Your Email"
                      variant="outlined"
                      fullWidth
                    />
                  </div>

                  <div className="mb-3 py-2">
                    <label for="phone_number" className="form-label">
                      Phone number *
                    </label>
                    <TextField
                      id="phone_number"
                      name="phone_number"
                      type="number"
                      label="Your Contact Number"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3 py-2">
                    <label for="desire_service" className="form-label">
                      Service you want *
                    </label>
                    <TextField
                      id="desire_service"
                      name="desire_service"
                      type="text"
                      label="Your Desired Service"
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  <div className="mb-3 py-2">
                    <label for="company_name" className="form-label">
                      Company name (Optional)
                    </label>
                    <TextField
                      id="company_name"
                      name="company_name"
                      type="text"
                      label="Your Company Name"
                      variant="outlined"
                      fullWidth
                    />
                  </div>

                  <div className="container-btn">
                    <>
                      <button className="btn" type="submit" style={{ background: "#B31942" }}>
                        <svg
                          width="150px"
                          height="50px"
                          viewBox="0 0 180 60"
                          className="border svg-btn"
                        >
                          <polyline
                            points="179,1 179,59 1,59 1,1 179,1"
                            className="bg-line"
                          />
                          <polyline
                            points="179,1 179,59 1,59 1,1 179,1"
                            className="hl-line"
                          />
                        </svg>
                        <span style={{ color: "white" }}>Submit</span>
                      </button>
                    </>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
}
