import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BsFillPersonFill, BsFillPinFill } from "react-icons/bs";
import { BiSolidCustomize, BiSolidPhoneCall } from "react-icons/bi";
import { FaHandsHelping } from "react-icons/fa";
import { PiStrategyBold } from "react-icons/pi";
import { Element } from "react-scroll";

export default function ChooseUs() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Element name="motionAnimation">
      {scrolled && (
        <motion.div className="choose-container">
          <div className="choose-strip" style={{ marginTop: "100px" }}>
            <h2 className="hero-title chooseText">Why Choose Us ?</h2>
            <p style={{ textAlign: "center" }}>
              There are many valid reasons why you should choose us to take your
              businesses to next level.
            </p>
          </div>
          <motion.div>
            <div class="card-group my-5">
              <div class="card client-card choose-scroll">
                <div class="choose__card">
                  <BsFillPersonFill alt="us digital marketing experienced professional" />
                </div>
                <div class="card-body">
                  <h3>
                    <b>Experienced Professionals</b>
                  </h3>
                  <p class="card-text">
                    US Digital Marketing Have a team of Experienced
                    Professionals with worth mentioning skills. Reach out and
                    try them!
                  </p>
                </div>
              </div>
              <div class="card client-card choose-scroll">
                <div class="choose__card">
                  <BiSolidCustomize alt="tailored solutions from us digital marketing" />
                </div>
                <div class="card-body">
                  <h3>
                    <b>Tailored Solutions</b>
                  </h3>
                  <p class="card-text">
                    You need to find tailored solutions for your Business to
                    grow and achieve the desired results. Digital Marketing is
                    the one you should look for.
                  </p>
                </div>
              </div>
              <div class="card client-card choose-scroll">
                <div class="choose__card">
                  <BsFillPinFill alt="manifest outcomes from us digital marketing" />
                </div>
                <div class="card-body">
                  <h3>
                    <b>Manifest Outcomes</b>
                  </h3>
                  <p class="card-text">
                    We do proper planning and make strategies to derive desired
                    outcomes for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div class="card-group my-5">
              <div class="card client-card choose-scroll">
                <div class="choose__card">
                  <PiStrategyBold alt="radical strategies from us digital marketing" />
                </div>
                <div class="card-body">
                  <h3>
                    <b>Radical Strategies</b>
                  </h3>
                  <p class="card-text">
                    US Digital Marketing Plans Radical strategies to benefit
                    clients more significantly.
                  </p>
                </div>
              </div>
              <div class="card client-card choose-scroll">
                <div class="choose__card">
                  <FaHandsHelping alt="agreeable service from us digital marketing" />
                </div>
                <div class="card-body">
                  <h3>
                    <b>Agreeable Service</b>
                  </h3>
                  <p class="card-text">
                    We provide Service with mutual agreement and accept
                    modifications as per your choice because we prefer
                    coordination.
                  </p>
                </div>
              </div>
              <div class="card client-card choose-scroll">
                <div class="choose__card">
                  <BiSolidPhoneCall alt="on call 7 days from us digital marketing" />
                </div>
                <div class="card-body">
                  <h3>
                    <b>On Call 7 Days</b>
                  </h3>
                  <p class="card-text">
                    US Digital Marketing Responds to calls/queries from Monday
                    to Friday (excluding holidays). And usually responds the
                    same day when the request is made.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </Element>
  );
}
