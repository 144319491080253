import React, { useRef } from 'react';
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";

function ContactForm() {
    const form = useRef();

    // Function to send email
    const handleSubmitForm = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_uubi856",
                "template_64tc5qc",
                form.current,
                "F3fCl9xjwtltwxCp7"
            )
            .then(
                (result) => {
                    Swal.fire({
                        icon: "success",
                        title: "Message Sent!",
                        text: "Your message has been received. We will get back to you soon.",
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        },
                    });
                },
                (error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "An error occurred while sending your message. Please try again later.",
                        confirmButtonText: "OK",
                        customClass: {
                            confirmButton: 'custom-confirm-button-class',
                        },
                    });
                }
            );
    }

    return (
        <>
            <div className='container-fluid' >
                <div className="row" style={{ marginTop: "70px", marginBottom: "10px" }}>
                    <style>
                        {`
                        #envelope {
                            animation: float 2s ease-in-out infinite;
                        }

                        #star1,
                        #star2,
                        #star3,
                        #star4,
                        #star5,
                        #star6 {
                            animation: blink 1s ease-in-out infinite;
                        }

                        #star2 {
                            animation-delay: 100ms;
                        }

                        #star3 {
                            animation-delay: 500ms;
                        }

                        #star4 {
                            animation-delay: 700ms;
                        }

                        #star5 {
                            animation-delay: 300ms;
                        }

                        #star6 {
                            animation-delay: 200ms
                        }

                        @keyframes float {
                            0% {
                                transform: translateY(0px);
                            }

                            50% {
                                transform: translateY(-20px);
                            }

                            100% {
                                transform: translateY(0px);
                            }
                        }

                        @keyframes blink {
                            0% {
                                opacity: 0;
                            }

                            50% {
                                opacity: .5;
                            }

                            100% {
                                opacity: 1;
                            }
                        }

                          form .form-control {
                            background-color: #d8e3e8;
                            border-radius: 2rem;
                            border: none;
                            box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
                          }
                          
                          form .form-control.thick {
                            height: 3.3rem;
                            padding: .5rem 3.5rem;
                          }
                          
                          form .form-control:focus {
                            background-color: #d8e3e8;
                            border: none;
                            box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
                          }
                          
                          form .message .form-control {
                            padding: .5rem 1.8rem;
                          }
                          
                          form ::placeholder {
                            font-family: 'Quicksand', sans-serif;
                            font-weight: 600;
                            font-size: 1.1rem;
                            color: #838788;
                            position: relative;
                            left: 0;
                          }
                          
                          form input,
                          form textarea {
                            font-family: 'Quicksand', sans-serif;
                            color: #212529;
                            font-size: 1.1rem;
                          }
                          
                          form .icon {
                            color: #57565c;
                            height: 1.3rem;
                            position: absolute;
                            left: 1.5rem;
                            top: 1.1rem;
                          }
                          
                    `}
                    </style>
                    <div style={{ position: "relative" }}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16690.084199066394!2d-115.1198964!3d36.0264067!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8cf4eb7e4979f%3A0xf3c5f32cd1a5eb66!2sEastern%20Laptop%20Repair%2C%208965%20S%20Eastern%20Ave%20%23120K%2C%20Las%20Vegas%2C%20NV%2089123%2C%20United%20States!5e0!3m2!1sen!2sus!4v1644599147362!5m2!1sen!2sus"
                            style={{ opacity: "0.4", position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: "-1" }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            title='us digital marketing google map'
                        />
                        <div className='col-md-12 mt-5' style={{ zIndex: "999" }}>
                            <form onSubmit={handleSubmitForm} ref={form}>
                                <h1 className="title text-center mb-4"
                                    style={{ color: "black", fontWeight: "bold" }}>Reach <span style={{ color: "#B31942" }}>Us</span></h1>
                                <div className="form-group position-relative" style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <label for="name" className="d-block">
                                        <i className="icon" data-feather="user"></i>
                                    </label>
                                    <input type="text" id="name" name='name' style={{ width: "60%" }}
                                        className="form-control form-control-lg thick" placeholder="Name" />
                                </div>

                                <div className="form-group position-relative mt-4" style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <label for="email" className="d-block">
                                        <i className="icon" data-feather="mail"></i>
                                    </label>
                                    <input type="email" id="email" name="email" style={{ width: "60%" }}
                                        className="form-control form-control-lg thick" placeholder="E-mail" />
                                </div>
                                <div className="form-group position-relative mt-4" style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <label for="subject" className="d-block">
                                        <i className="icon" data-feather="mail"></i>
                                    </label>
                                    <input type="subject" id="subject" name="subject" style={{ width: "60%" }}
                                        className="form-control form-control-lg thick"
                                        placeholder="Subject" />
                                </div>
                                <div className="form-group message mt-4" style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <textarea id="message" name='message' style={{ width: "60%" }}
                                        className="form-control form-control-lg"
                                        rows="7" placeholder="Message"></textarea>
                                </div>

                                <div className="text-center mt-4 pb-5">
                                    <button type="submit" className="btn" style={{
                                        background: "#0A3161", color: "white", borderRadius: "30px"
                                    }}>Send message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ContactForm