import React from "react";
import { Link } from "react-router-dom";
import { FiArrowDownRight } from "react-icons/fi";
import MouseImg from "../../../assets/img/mouse.png"

function Hero() {
  const scrollToAbout = () => {
    const aboutSection = document.getElementById("myvideo");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  function handleScrollingEffect() {
    document.documentElement.style.overflowY = "auto";
    scrollToAbout();
  }

  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="container">
          <div className="row d-flex flex-wrap align-items-center slider-main">
            <div className="col-md-12">
              <div className="banner-content">
                <h5 className="hero-title mt-5">
                  <b style={{ color: "floralwhite" }}>LET'S GO !</b>
                </h5>
                <h1 className="card-title hero-title">
                  <span style={{ color: "floralwhite" }}>WHY{" "}</span>
                  <span style={{ color: "#da1441" }}>US DIGITAL MARKETING ?</span>
                </h1>
                <p className="card-text hero-text my-4">
                  US Digital Marketing Agency Introduces the World To New
                  Innovative and Smart Marketing Strategies to the Digital World.
                  It guarantees your Business the creative branding solutions that
                  you are looking for. It provides consistent Growth with proper
                  planning for your Business in the digital world. Our commitment,
                  passion, service and branding strategies make us an ideal
                  working partner to reach new heights.
                  <br />
                  Enlighten your Business , interact with your audience and
                  Achieve your results with US Digital Marketing.
                </p>
                <div className="buttons">
                  <button onClick={scrollToAbout} className="btn-5" style={{ background: "floralwhite", color: "black" }}>
                    Get Started <FiArrowDownRight className="button__icon" alt="arrow right"/>
                  </button>
                  <Link to="/work_with_us" className="btn-6">
                    Find Out More!
                  </Link>
                </div>
                <div className="main" onClick={() => { handleScrollingEffect() }}>
                  <div className="mouse">
                    <img src={MouseImg} alt={'mouse-logo'} width="50" height="50" />
                  </div>
                  <svg id="rotatingText" viewBox="0 0 200 200" width="200" height="200">
                    <defs>
                      <path id="circle" d="M 100, 100
                m -75, 0
                a 75, 75 0 1, 0 150, 0
                a 75, 75 0 1, 0 -150, 0
                ">
                      </path>
                    </defs>
                    <text width="400">
                      <textPath alignmentBaseline="top" href="#circle" className="text">
                        Click To Scroll Down Together
                      </textPath>
                    </text>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-background"></div>
    </div>
  );
}

export default Hero;
