import React, { useCallback, useEffect } from "react";
import { UserLogin } from "../../../context/AuthContext";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { PiCaretUpBold } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import GetQuote from "../../Quote.js/GetQuote";

function Navbar() {
  const location = useLocation();
  const {
    setModalOpen,
    stickyNav,
    setstickyNav,
    toTop,
    settoTop,
    activeItem,
    setActiveItem,
    nav,
    setNav,
  } = UserLogin();

  const onOpen = () => {
    setNav(!nav);
  };

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY || window.pageYOffset;
    scrollY > stickyNav ? setstickyNav(true) : setstickyNav(false);
    scrollY > 300 ? settoTop(true) : settoTop(false);
  }, [stickyNav, setstickyNav, settoTop]);

  useEffect(() => {
    const navbar = document.querySelector(nav);
    if (navbar) {
      const rect = navbar.getBoundingClientRect();
      setstickyNav(rect.height);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [nav, handleScroll, setstickyNav]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    setNav(false);
  };

  return (
    <>
      {/* Button show when scroll */}
      <button
        type="button"
        className="navigate-up"
        style={{ display: toTop ? "flex" : "none" }}
        onClick={scrollToTop}
      >
        <PiCaretUpBold alt="move to top"/>
      </button>

      {/* Navbar overlay and color when screen size smaller */}
      {nav && <div className="overlay" onClick={() => setNav(false)}></div>}

      {/* Navbar Menu to show items when screen size smaller */}
      <ul
        className={`navbar-nav mobile-nav d-xl-none d-lg-none d-block ${nav && "open-mobile-nav"
          } gap-xl-4 gap-lg-4 gap-0 mt-xl-0 mt-lg-0 mt-4`}
      >
        <div className="d-flex justify-content-end">
          <button type="button" className="close-btn">
            <MdOutlineClose
              className="close-icon"
              onClick={() => setNav(false)}
              alt="close nav"
            />
          </button>
        </div>
        <li
          className={`nav-items ${activeItem === "Home" ? "red-background" : ""
            }`}
        >
          <Link
            to="/"
            className="nav-link-small"
            onClick={() => handleItemClick("Home")}
          >
            Home
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "about_us" ? "red-background" : ""
            }`}
        >
          <Link
            to="/about_us"
            className="nav-link-small"
            onClick={() => handleItemClick("about_us")}
          >
            Our Story
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "social_marketing" ? "red-background" : ""
            }`}
        >
          <Link
            to="/social_marketing"
            className="nav-link-small"
            onClick={() => handleItemClick("social_marketing")}
          >
            Social Media Marketing
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "web_development" ? "red-background" : ""
            }`}
        >
          <Link
            to="/web_development"
            className="nav-link-small"
            onClick={() => handleItemClick("web_development")}
          >
            Website Development
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "database_programming" ? "red-background" : ""
            }`}
        >
          <Link
            to="/database_programming"
            className="nav-link-small"
            onClick={() => handleItemClick("database_programming")}
          >
            Database Programming
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "seo" ? "red-background" : ""
            }`}
        >
          <Link
            to="/seo"
            className="nav-link-small"
            onClick={() => handleItemClick("seo")}
          >
            SEO Service
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "branding" ? "red-background" : ""
            }`}
        >
          <Link
            to="/branding"
            className="nav-link-small"
            onClick={() => handleItemClick("branding")}
          >
            Branding Strategy
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "content_writing" ? "red-background" : ""
            }`}
        >
          <Link
            to="/content_writing"
            className="nav-link-small"
            onClick={() => handleItemClick("content_writing")}
          >
            Content Writing
          </Link>
        </li>

        <li
          className={`nav-items ${activeItem === "Work" ? "red-background" : ""
            }`}
        >
          <Link
            to="/work_with_us"
            className="nav-link-small"
            onClick={() => handleItemClick("Work")}
          >
            Work With Us
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "Talk" ? "red-background" : ""
            }`}
        >
          <Link
            to="/Talk"
            className="nav-link-small"
            onClick={() => handleItemClick("Talk")}
          >
            Talk To Us
          </Link>
        </li>
        <li
          className={`nav-items ${activeItem === "Blogs" ? "red-background" : ""
            }`}
        >
          <Link
            to="/our_blogs"
            className="nav-link-small"
            onClick={() => handleItemClick("Blogs")}
          >
            Blog
          </Link>
        </li>
      </ul>

      {/* Navbar Menu to show items when screen size larger */}
      <nav
        className={`navbar py-3 ${stickyNav && "fixed-top"
          } px-xl-5 px-2 navbar-expand-lg navbar-light d-flex justify-content-between`}
      >
        <div className="brand">
          <Link className="logo-name" to="/">
            <img src={logo} alt="CDS Logo" className="logo" />
          </Link>
        </div>
        <ul className="navbar-nav navbar-links d-xl-flex d-lg-flex d-none mt-xl-0 mt-lg-0 mt-4">
          <li className={`nav-items`}>
            <Link
              className={`nav-link ${location.pathname === "/" ? "active" : ""
                }`}
              to="/"
            >
              Home
            </Link>
          </li>
          <li className={`nav-items`}>
            <Link
              className={`nav-link ${location.pathname === "/about_us" ? "active" : ""
                }`}
              to="/about_us"
            >
              Our Story
            </Link>
          </li>
          <li
            className={`nav-items dropdown d-flex gap-2 align-items-center`}
          >
            <Link
              className={`nav-link ${location.pathname === "/Services" ? "active" : ""}`}
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              to="/Services"
            >
              Services
            </Link>
            <ul className="dropdown-menu dropdown-width my-3">
              <div className="row">
                <div className="col">
                  &nbsp;
                  <li>
                    <Link className="dropdown-item" to="/social_marketing">
                      Social Media Marketing
                    </Link>
                    &nbsp;
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/web_development">
                      Website Development
                    </Link>
                  </li>
                  &nbsp;
                  <li>
                    <Link className="dropdown-item" to="/database_programming">
                      Database Programming
                    </Link>
                  </li>
                  &nbsp;
                </div>
                <div className="col">
                  &nbsp;
                  <li>
                    <Link className="dropdown-item" to="/seo">
                      SEO Service
                    </Link>
                    &nbsp;
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/branding">
                      Branding Strategy
                    </Link>
                  </li>
                  &nbsp;
                  <li>
                    <Link className="dropdown-item" to="/content_writing">
                      Content Writing
                    </Link>
                  </li>
                  &nbsp;
                </div>
              </div>
            </ul>
          </li>
          <li
            className={`nav-items`}
          >
            <Link
              className={`nav-link ${location.pathname === "/work_with_us" ? "active" : ""
                }`}
              to="/work_with_us"
            >
              Work With Us
            </Link>
          </li>
          <li
            className={`nav-items`}
          >
            <Link
              className={`nav-link ${location.pathname === "/Talk" ? "active" : ""
                }`}
              to="/Talk"
            >
              Talk To Us
            </Link>
          </li>
          <li
            className={`nav-items ${activeItem === "Blogs" ? "red-background" : ""
              }`}
          >
            <Link
              className={`nav-link ${location.pathname === "/our_blogs" ? "active" : ""
                }`}
              to="/our_blogs"
            >
              Blog
            </Link>
          </li>
        </ul>
        <div className="dropdown d-flex gap-2 align-items-center">
          <Link
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <span className="navbar-button">Get a Quote</span>
          </Link>
          <GetQuote />
          <button
            type="button"
            className="toggle-btn d-xl-none d-lg-none d-block"
            onClick={onOpen}
          >
            <FaBars alt="small bars"/>
          </button>
        </div>
      </nav>

    </>
  );
}

export default Navbar;
