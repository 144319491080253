import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Element } from "react-scroll";
import { UserLogin } from "../../../context/AuthContext";

export default function About() {
  const { scrolled,
    scrollToTop } = UserLogin();

  return (
    <Element name="motionAnimation">
      {scrolled && (
        <div id="about" className="card about-card w-100 h-100 mb-3 about-container" style={{ marginTop: "50px" }}>
          <div className="card-body">
            <motion.div
              className="col-md-12 about-second-half order-1 order-md-0 about-scroll-second">
              <h2 className="hero-title mt-5 px-5">
                YOUR TRUSTED <br />
                <span style={{ color: "#B31942" }}>
                  DIGITAL MARKETING PARTNER
                </span>
              </h2>
              <div className="trusted-container">
                <div className="marquee-container">
                  <div class="marquee marquee--left marquee--spaced marquee--heading-font marquee--animate" role="marquee">
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                    <span class="marquee-text">US Digital Marketing, Where Strategy Meets Success</span>
                  </div>
                </div>
              </div>
              <p className="card-text about-text-title px-4" style={{ textAlign: "right" }}>
                US Digital Marketing comes up with a fantastic experience of
                reliable services worth Mentioning. You can avail of
                services like <br />
                <b>
                  online marketing, branding strategies, web design, search
                  engine optimization, database programming, and <br /> product
                  advertisement.
                </b>
                With Expertise In Developing the Digital World and
                Commitment to delivering Exceptional results, <br /> we look
                forward to growing your Business globally. <br />
                US Digital Marketing Service delivers
                <b> Customer-satisfying results.</b>
                <div className="about-btn">
                  <Link className="glow-on-hover" to="/about_us" onClick={scrollToTop}>
                    More About Us
                  </Link>
                </div>
              </p>
            </motion.div>
          </div>
        </div>
      )}
    </Element>
  );
}
