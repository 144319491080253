import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Section1/Navbar";
import Hero from "./Section2/Hero";
import About from "./Section3/About";
import Carousal from "./Section5/Carousal";
import Client from "./Section6/Client";
import Questions from "./Section6/Questions";
import Footer from "./Section6/Footer";
import ChooseUs from "./Section4/ChooseUs";
import Top from "./Section1/Top";
import ContactForm from "./Section7/ContactForm";
import VideoAbout from "./Section3/VideoAbout";
import LetsFind from "./Section7/LetsFind";

function Main() {
  return (
    <>
      <Helmet>
        <title>Why Choose US Digital Marketing?</title>
        <meta
          name="description"
          content="US Digital Marketing Agency Introduces the World To New Innovative and Smart Marketing Strategies to the Digital World. It provides growth for your business."
        />
      </Helmet>

      <Top />
      <Navbar />
      <Hero />
      <VideoAbout />
      <About />
      <Carousal />
      <ChooseUs />
      <Client />
      <Questions />
      <ContactForm />
      <LetsFind />
      <Footer />
    </>
  );
}

export default Main;
