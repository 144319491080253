import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import { Element } from "react-scroll";
import { useNavigate } from "react-router";

export default function Carousal() {
  let navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Element name="motionAnimation">
      {scrolled && (
        <motion.div className="carosoul-container"
        >
          <br />
          <br />
          <div
            id="carouselExampleAutoplaying"
            className="carousel slide my-5"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <motion.div>
                <h5 className="hero-title large-text">
                  This is what we do to Transform your <br />
                  <span style={{ color: "#0A3161" }}>Business Digitally</span>
                </h5>
              </motion.div>

              <br />
              <div className="carousel-item active">
                <div className="row">
                  <div className="col">
                    <Lottie
                      animationData={require("../../../assets/animation/seo.json")}
                      loop
                      autoplay
                      className="carousal-img d-block w-100"
                      onClick={() => {
                        navigate("/seo");
                        window.scrollTo(0, 0);
                      }}
                    />
                    <p className="text-center">
                      <b>Search Engine Optimization</b>
                    </p>
                  </div>
                  <div className="col">
                    <Lottie
                      animationData={require("../../../assets/animation/performance.json")}
                      loop
                      autoplay
                      className="carousal-img d-block w-100"
                      onClick={() => {
                        navigate("/database_programming");
                        window.scrollTo(0, 0);
                      }}
                    />
                    <p className="text-center">
                      <b>Database Programming</b>
                    </p>
                  </div>
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <Lottie
                      animationData={require("../../../assets/animation/digital.json")}
                      loop
                      autoplay
                      className="carousal-img d-block w-100"
                      onClick={() => {
                        navigate("/social_marketing");
                        window.scrollTo(0, 0);
                      }}
                    />
                    <p className="text-center">
                      <b>Digital Marketing</b>
                    </p>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className="row">
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <Lottie
                      animationData={require("../../../assets/animation/instagram.json")}
                      loop
                      autoplay
                      className="carousal-img d-block w-100"
                      onClick={() => {
                        navigate("/content_writing");
                        window.scrollTo(0, 0);
                      }}
                    />
                    <p className="text-center">
                      <b>Content Writing</b>
                    </p>
                  </div>
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <Lottie
                      animationData={require("../../../assets/animation/website.json")}
                      loop
                      autoplay
                      className="carousal-img d-block w-100"
                      onClick={() => {
                        navigate("/web_development");
                        window.scrollTo(0, 0);
                      }}
                    />
                    <p className="text-center">
                      <b>Website Development</b>
                    </p>
                  </div>
                  <div className="col d-flex flex-column align-items-center justify-content-center">
                    <Lottie
                      animationData={require("../../../assets/animation/brand.json")}
                      loop
                      autoplay
                      className="carousal-img d-block w-100"
                      onClick={() => {
                        navigate("/branding");
                        window.scrollTo(0, 0);
                      }}
                    />
                    <p className="text-center">
                      <b>Brand Marketing</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </motion.div>
      )}
    </Element>
  );
}
