import React from 'react';
import { HiMiniArrowTopRightOnSquare } from "react-icons/hi2";
import { Link } from 'react-router-dom';
import { UserLogin } from '../../../context/AuthContext';

function LetsFind() {
    const {
        scrollToTop } = UserLogin();

    return (
        <div className='row mt-3'>
            <div class="col-md-10 col-10 col-sm-12 letsfind-container">
                <p>Let's make something <span id="spin"></span> !</p>
            </div>

            <div class="col-md-2 col-2 col-sm-12 get_in_touch_btn mt-3" >
                <Link to="/talk" onClick={scrollToTop}>Get in touch <HiMiniArrowTopRightOnSquare alt="get in touch with us digital marketing icon"/></Link>
            </div>
        </div>
    )
}

export default LetsFind