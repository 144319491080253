import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import Footer from "../Home/Section6/Footer";
import Navbar from "../Home/Section1/Navbar";
import Divider from "@mui/material/Divider";
import { AiFillStar } from "react-icons/ai";
import LetsFind from "../Home/Section7/LetsFind";

export default function SEO() {
  const services = [
    "On-Page SEO.",
    "Keyword Research.",
    "Off-Page SEO.",
    "Technical SEO.",
    "Local SEO.",
    "Additional Value",
    "E-commerce SEO.",
    "Competitor Analysis",
    "Analytics and Reporting",
  ];

  const summaries = [
    "we carefully optimize your website content and structure to align with Search Engine Optimization and user intent. This includes Keywords analysis Title tag optimization Meta descriptions Content quality improvement",
    "In our SEO service, we carefully search keywords after deep research of your industry, audience, and competitors To find suitable search terms. We use high search volume keywords to ensure your website's long-lasting success. With this process, our SEO consultant ensures that we target the most relevant terms and maximize your chances of website rank in the competitive world of freelancing.",
    "We create a strong network of top-quality backlinks from reputable websites, single trust, and relevance to search engine optimization. We use helpful methods for building links. Our methods are ethical and built for the long haul, measuring the long-lasting success of your website.",
    "We ensure your website's technical setup is good and covers areas like Site speed Mobile-friendliness Structured data markup Indexability This solid technical foundation helps search engines understand your content quickly and enhance your website visibility and performance.",
    "To evaluate your website's SEO status, SEO specialist conduct thorough audits, point out areas for enhancement, and offer suitable suggestions. Our reports enable you to monitor.",
    "While these categories showcase our detailed approach. Our SEO agency offers other services like WordPress SEO and consulting services to address specific needs.",
    "If you have an online store, we provide a good SEO approach to increase your store visibility. Optimise product pages. And improve the shopping cart experience. Our SEO approach converts visitors into your store's loyal customers.",
    "We deeply analyze your competitors' and SEO strategies and find their weaknesses and strengths. Based on this information, we plan to outperform them in the search engine race.",
    "We stay current with the latest trends by monitoring essential SEO metrics and delivering detailed reports.",
  ];

  return (
    <>
      <Helmet>
        <title>Boost Your Online Presence with Expert SEO Service!</title>
        <meta
          name="description"
          content="Enhance your online visibility with UDM's SEO services. Our expert team helps you to rank your website on top in search results. So let us optimise your website."
        />
      </Helmet>


      <Navbar />
      <motion.div className="seo-background">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div
            className=""
            style={{ background: "transparent", textAlign: "center" }}
          >
            <h1 className="hero-title">
              <b style={{ color: "#B31942" }}>Why UDM Is The Best Choice For<br />
              </b>{" "}
              <b style={{ color: "#0A3161" }}>SEO Service</b>
            </h1>
          </div>
          <div className="container web-description">
            <p>
              In today's world, it's essential to stay online. At US Digital Marketing, we specialise in boosting your online presence through SEO services. Our primary focus is improving your website's visibility to more people, attracting relevant traffic, and driving conversation. We achieve this goal by optimizing various elements, such as
              Keywords
              Content
              User experience
              To ensure that your website ranks on top in search engine optimization with our good SEO services. With our complete SEO services, we enlarge your business to reach the next level and effectively connect with the target audience. Choose UDM to maximize your online potential with our SEO service.

            </p>
            <h2 className="my-5">
              <b>Let's Explore The Main Components Of Our SEO Service</b>
            </h2>
            <Divider>
              <AiFillStar size={20} color="#da1441"  alt= "us digital marketing star"/>
            </Divider>
          </div>
          <div className="container web-ul">
            <ul style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}>
              {services.map((service, index) => (
                <li key={index} style={{ listStyle: "none" }}>
                  <details>
                    <summary style={{ outline: "none" }}>
                      <h3>
                        <AiFillStar size={20} color="#da1441" alt= "us digital marketing star 1" /> {service}
                      </h3>
                    </summary>
                    <p>{summaries[index]}</p>
                  </details>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </motion.div>
      <LetsFind />
      <Footer />
    </>
  );
}
