import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import Footer from "../Home/Section6/Footer";
import Navbar from "../Home/Section1/Navbar";
import Divider from "@mui/material/Divider";
import { AiFillStar } from "react-icons/ai";
import LetsFind from "../Home/Section7/LetsFind";

export default function Branding() {
  return (
    <>
      <Helmet>
        <title>Rise Your Business with our Branding Strategy Service!</title>
        <meta
          name="description"
          content="Are you looking for a brand strategy service? At UDM, we are here for your brand growth. We provide you with everything required for a good brand."
        />
      </Helmet>

      <Navbar />
      <div className="branding-container">
        <motion.div
          className="branding-background" >
          <div className="screens-height" style={{ marginTop: "100px" }}>
            <div
              className=""
              style={{ background: "transparent", textAlign: "center" }}
            >
              <h1 className="hero-title">
                <b style={{ color: "#B31942" }}>Branding</b>{" "}
                <b style={{ color: "#0A3161" }}>Strategy</b>
              </h1>
            </div>
            <div className="container web-description">
              <p>
                At UDM, we understand the importance of solid brand strategy in today's digital business. We provide an excellent logo and tagline, creating a strong and long-lasting connection with our clients.
                Our priority is to help customers to achieve their goals in a highly competitive digital market. Our primary focus during our service is clear client communication, catchy designs, strategic positioning, and continuous adaptation.
                These work like a pillar in our brand strategy service. And with our best service, we ensure that our client stands out and succeeds in the fast-changing digital world. So, if you choose UDM, we will guide you on achieving success with a simple and effective business strategy.
              </p>
              <Divider>
                <AiFillStar size={20} color="#da1441" alt= "us digital marketing star"/>
              </Divider>
            </div>

            <div className="px-5 mt-5">
              <div className="card-group">
                <div className="card branding-card">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="main"
                        style={{ textAlign: "left", alignItems: "left", justifyContent: "left" }}
                      >
                        <svg id="rotatingText" viewBox="0 0 200 200" width="200" height="200">
                          <defs>
                            <path id="circle" d="M 100, 100
                                                m -75, 0
                                                a 75, 75 0 1, 0 150, 0
                                                a 75, 75 0 1, 0 -150, 0
                                                ">
                            </path>
                          </defs>
                          <text width="400">
                            <textPath alignmentBaseline="top" href="#circle" className="text">
                              Brand Positioning  Brand Positioning
                            </textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="card-body">
                        <div className="portfolio-story-content">
                          <div className="row-branding">
                            <h2>
                              <b>Brand <br /> Positioning</b>
                            </h2>
                            <div className="layer-branding">
                              <p>
                                First, we understand what makes your brand special, unique, and memorable.
                                We do this brand <br /> strategy task through clear communication
                                with you, and at the same time, we analyze the market. <br />

                                We find out who your target audience is, what makes your brand strong, and what
                                makes your brand different <br /> and unique from the competition.
                                It helps us position your brand well. At UDM, our top priority is to make sure that <br />
                                your brand is reaching your target audience.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-group" style={{ marginTop: "10%" }}>
                <div className="card branding-card">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="card-body">
                        <div className="portfolio-story-content">
                          <div className="row-branding" style={{textAlign: "right"}}>
                            <h2>
                              <b>Brand <br /> Guidelines</b>
                            </h2>
                            <div className="layer-branding">
                              <p className="py-5 px-5">
                                Once we clearly understand the brand vision and values, we will guide<br /> you to be an authentic brand.
                                We offer complete branding strategy services, designing the
                                identity that reflects your values and attracts  <br />
                                your target person.

                                This covers logo designing, color selection, establishing voice, and crafting the message. <br />
                                We want your brand to keep a unified and creative look on all channels so <br />
                                that it will be the best and most visible everywhere. At UDM, we're here to help you build
                                a prominent and influential brand presence.

                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="main"
                        style={{ textAlign: "left", alignItems: "left", justifyContent: "left" }}
                      >
                        <svg id="rotatingText" viewBox="0 0 200 200" width="200" height="200">
                          <defs>
                            <path id="circle" d="M 100, 100
                                                m -75, 0
                                                a 75, 75 0 1, 0 150, 0
                                                a 75, 75 0 1, 0 -150, 0
                                                ">
                            </path>
                          </defs>
                          <text width="400">
                            <textPath alignmentBaseline="top" href="#circle" className="text">
                              Brand Guidelines Brand Guidelines
                            </textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-group" style={{ marginTop: "10%" }}>
                <div className="card branding-card">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="main"
                        style={{ textAlign: "left", alignItems: "left", justifyContent: "left" }}
                      >
                        <svg id="rotatingText" viewBox="0 0 200 200" width="200" height="200">
                          <defs>
                            <path id="circle" d="M 100, 100
                                                m -75, 0
                                                a 75, 75 0 1, 0 150, 0
                                                a 75, 75 0 1, 0 -150, 0
                                                ">
                            </path>
                          </defs>
                          <text width="400">
                            <textPath alignmentBaseline="top" href="#circle" className="text">
                              Brand Evolution Brand Evolution
                            </textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="card-body">
                        <div className="portfolio-story-content">
                          <div className="row-branding">
                            <h2>
                              <b>Brand <br /> Evolution</b>
                            </h2>
                            <div className="layer-branding">
                              <p className="py-5 px-5">
                                We consider brands as living creatures that need to grow and evolve to succeed. Our brand's strategy service helps you deal with change nicely. We provide you with strategic guidance on the brand growth, its image redecorated, and ensuring its digital presence is ageless.<br />
                                That way, your brand remains related to the target audience and delivers the highest possible results in the ever-changing digital world. At UDM, we are here to ensure your brand grows
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-group" style={{ marginTop: "10%" }}>
                <div className="card branding-card">
                  <div className="row">
                    <div className="col-md-10">
                      <div className="card-body">
                        <div className="portfolio-story-content">
                          <div className="row-branding" style={{textAlign: "right"}}>
                            <h2>
                              <b>Brand <br /> Monitoring</b>
                            </h2>
                            <div className="layer-branding">
                              <p className="py-5 px-5">
                                Keeping your brand strategy updated is a must to maintain pace with the fast-changing digital reality. Social monitoring is only one part of our brand monitoring service.
                                <br />Our attention is fixed on the industry developments, the behavior of your competitors, and how people perceive your brand. This gives you helpful information to enable the use of intelligence in making decisions.
                                <br /> Being proactive means always having an up-to-date brand, constantly talking to your audience, and being ahead of your industry. We at UDM are committed to ensuring that your brand stays relevant to the current times and steps early in the fast digital world.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="main"
                        style={{ textAlign: "left", alignItems: "left", justifyContent: "left" }}
                      >
                        <svg id="rotatingText" viewBox="0 0 200 200" width="200" height="200">
                          <defs>
                            <path id="circle" d="M 100, 100
                                                m -75, 0
                                                a 75, 75 0 1, 0 150, 0
                                                a 75, 75 0 1, 0 -150, 0
                                                ">
                            </path>
                          </defs>
                          <text width="400">
                            <textPath alignmentBaseline="top" href="#circle" className="text">
                              Brand Monitoring Brand Monitoring
                            </textPath>
                          </text>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      <LetsFind />
      <Footer />
    </>
  );
}
