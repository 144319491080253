import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Footer from "../Home/Section6/Footer";
import Navbar from "../Home/Section1/Navbar";
import { AiOutlineFileSearch } from "react-icons/ai";
import { CgWebsite } from "react-icons/cg";
import { LuNewspaper } from "react-icons/lu";
import { BsFillDatabaseFill, BsFillPersonCheckFill } from "react-icons/bs";
import { TfiLayoutMediaRightAlt } from "react-icons/tfi";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

export default function Work() {
  let navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  const animateButton = (e) => {
    e.preventDefault();
    e.target.classList.remove("animate");
    e.target.classList.add("animate");
    setTimeout(function () {
      e.target.classList.remove("animate");
      navigate("/Talk");
      window.scrollTo(0, 0);
    }, 700);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>US Digital Marketing | Hire</title>
        <meta
          name="description"
          content="Enhance your online presence with UDM's branding services. We specialize in on-page SEO, keyword research, off-page SEO, technical SEO, local SEO, e-commerce SEO, competitor analysis, analytics, and reporting."
        />
      </Helmet>


      <Navbar />
      <motion.div className="work-background">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div
            className=""
            style={{ background: "transparent", textAlign: "center" }}
          >
            <h3 className="hero-title">
              <b style={{ color: "#0A3161" }}>Hire a </b>
              <b style={{ color: "#B31942" }}>Talent</b>
            </h3>
          </div>
          <div className="container web-ul">
            {/* Section 1 */}
            <div className="card-section container-lg my-5">
              <div className="row justify-content-center">
                {/* First Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div className={`card-work`}>
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <CgWebsite
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                              alt= "us digital marketing website development"
                            />
                          </p>
                          <h4 className="text-center">Website Developer</h4>
                        </div>
                        <button
                          className="bubbly-button text-center"
                          onClick={animateButton}
                        >
                          Hire Now!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Second Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div className={`card-work`}>
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <BsFillDatabaseFill
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                              alt= "us digital marketing database"
                            />
                          </p>
                          <h4 className="text-center">Database Expert</h4>
                        </div>
                        <button
                          className="bubbly-button text-center"
                          onClick={animateButton}
                        >
                          Hire Now!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 2 */}
            <div className="card-section container-lg my-5">
              <div className="row justify-content-center">
                {/* Third Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div className={`card-work `}>
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <AiOutlineFileSearch
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                              alt= "us digital marketing seo"
                            />
                          </p>
                          <h4 className="text-center">SEO Expert</h4>
                        </div>
                        <button
                          className="bubbly-button text-center"
                          onClick={animateButton}
                        >
                          Hire Now!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Fourth Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div className={`card-work `}>
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <TfiLayoutMediaRightAlt
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                              alt= "us digital marketing social media expert"
                            />
                          </p>
                          <h4 className="text-center">Social Media Expert</h4>
                        </div>
                        <button
                          className="bubbly-button text-center"
                          onClick={animateButton}
                        >
                          Hire Now!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 3 */}
            <div className="card-section container-lg my-5">
              <div className="row justify-content-center">
                {/* Fivth Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div className={`card-work `}>
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <LuNewspaper
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                              alt= "us digital marketing content writing"
                            />
                          </p>
                          <h4 className="text-center">Content Writer</h4>
                        </div>
                        <button
                          className="bubbly-button text-center"
                          onClick={animateButton}
                        >
                          Hire Now!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Sixth Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div className={`card-work `}>
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <BsFillPersonCheckFill
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                              alt= "us digital marketing branding"
                            />
                          </p>
                          <h4 className="text-center">Branding Expert</h4>
                        </div>
                        <button
                          className="bubbly-button text-center"
                          onClick={animateButton}
                        >
                          Hire Now!
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
}
