import React, { useState } from "react";
import { motion } from "framer-motion";

export default function Questions() {

  const faqWork = [
    {
      question: "What services does US Digital Marketing offer?",
      answer:
        "US Digital Marketing Provides a reliable and extensive range of services in the digital world, including SEO, Social Media Marketing, Branding strategy, design development, Content writing, and database Programming. We create a smooth pathway that leads to the Growth of your Business.",
    },
    {
      question: "How can digital marketing benefit my business?",
      answer:
        "In this Era of Technology, Digital marketing is of great significance. It is cost-effective, has easy customer reach, unlimited clients, targeted results, more client interaction, greater brand credibility, and much more.",
    },
    {
      question: "What sets US Digital Marketing apart from other agencies?",
      answer:
        "That's a Question everybody is Looking for. Digital Marketing introduces new marketing,  branding strategies for clients, this creative digital agency gives creative ideas for businesses to grow, and most importantly, customer reviews set US Digital Marketing Ahead of all. The Growth of our Customers' Business says it all.",
    },
    {
      question: "Is digital marketing suitable for small businesses?",
      answer:
        "Yes, of course. Digital marketing provides a flexible environment to all customers. It iscost-efficientt and has unlimited reach. So It is suitable for every kind of Business.",
    },
    {
      question:
        "How long does it take to see results from digital marketing efforts?",
      answer:
        "Well, digital marketing can help improve website traffic through various strategies such as search engine optimization (SEO), content marketing, social media marketing, and email marketing. ",
    },
  ];

  const faqPrepare = [
    {
      question:
        "How do I measure the success of my digital marketing campaigns?",
      answer:
        "Measuring success is pretty straight forward. You can keep a track record of your website traffic and your audience engagement with your Business or content. Calculate your business targets. These indicators can lead to measuring success.",
    },
    {
      question: "Is my industry suitable for digital marketing?",
      answer:
        "Digital World has a flexible audience. This shows the diversity of Customers/clients in the digital world. So every industry, like textile, telecom, accessories,  etc., has proper opportunities to grow and promote their Business here.",
    },
    {
      question: "How can I get started with US Digital Marketing?",
      answer:
        "You could start with US Digital Marketing by contacting their website and requesting to contact the agency using the contact method mentioned on the page. Our team of professionals will reach out to you. Thanks",
    },
    {
      question: "Do you offer ongoing support and maintenance?",
      answer:
        "Yes, US Digital Marketing Provides Every possible ongoing support and Maintenance, usually from time to time.",
    },
    {
      question:
        "Can I see examples of your past work and client success stories?",
      answer:
        "Yes, you can contact the team to see the examples of past work and Success stories of clients mentioned in their Reviews on the digital marketing Agency's Website.",
    },
  ];

  const [showStates, setShowStates] = useState(
    Array(faqWork.length).fill(false)
  );

  const [helloShowStates, setHelloShowStates] = useState(
    Array(faqPrepare.length).fill(false)
  );

  const toggleQuestion = (index) => {
    const updatedStates = showStates.map((state, i) =>
      i === index ? !state : false
    );
    setShowStates(updatedStates);
  };

  const toggleHelloQuestion = (index) => {
    const updatedStates = helloShowStates.map((state, i) =>
      i === index ? !state : false
    );
    setHelloShowStates(updatedStates);
  };

  return (
    <motion.div className="faq-container">
      <div id="Faqs" className="container-fluid" style={{ marginTop: "100px" }}>
        <h2 className="hero-title faq-text">
          Frequently Asked <span style={{ color: "#0A3161" }}>Questions</span>
        </h2>
        <motion.div
          className="test-section row gap-3 px-3 justify-content-center my-3">
          {/* How To Work Div */}
          <div className="faq-work px-xl-3 px-lg-3 px-md-3 px-2">
            <div className="px-xl-4 px-lg-4 px-md-4 px-sm-2 px-1">
              {faqWork.map((q, index) => (
                <div className="question-section my-5 " key={index}>
                  <div
                    key={index}
                    className={`collapse-area ${showStates[index] && "collapse-show"
                      }`}
                    onClick={() => toggleQuestion(index)}
                  >
                    <h3
                      className={`question ${showStates[index] && "question-active"
                        }`}
                    >
                      {q.question}
                    </h3>
                    {showStates[index] && (
                      <p className="answer my-4">{q.answer}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* How to Prepare Div */}
          <div className="faq-prepare px-xl-3 px-lg-3 px-md-3 px-2">
            <div className="px-xl-4 px-lg-4 px-md-4 px-sm-2 px-1">
              {faqPrepare.map((q, index) => (
                <div className="question-section my-5 " key={index}>
                  <div
                    key={index}
                    className={`collapse-area ${helloShowStates[index] && "collapse-show"
                      }`}
                    onClick={() => toggleHelloQuestion(index)}
                  >
                    <h3
                      className={`question ${helloShowStates[index] && "question-active"
                        }`}
                    >
                      {q.question}
                    </h3>
                    {helloShowStates[index] && (
                      <p className="answer my-4">{q.answer}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
}
