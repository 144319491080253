import React, { useRef } from 'react';
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import { TextField, MenuItem } from "@mui/material";
import call from "../../assets/img/call.svg";
import email from "../../assets/img/Mail.svg";
import support from "../../assets/img/Icon-help.svg";
import ReactCountryFlag from "react-country-flag";
import Navbar from "../Home/Section1/Navbar";
import Footer from "../Home/Section6/Footer";
import { useNavigate } from "react-router";

function Talk() {
  let navigate = useNavigate();
  const form = useRef();

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua & Deps",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Central African Rep",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo {Democratic Rep}",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland {Republic}",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea North",
    "Korea South",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar, {Burma}",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "St Kitts & Nevis",
    "St Lucia",
    "Saint Vincent & the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome & Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  function getCountryCode(countryName) {
    const countryCodes = {
      Afghanistan: "AF",
      Albania: "AL",
      Algeria: "DZ",
      Andorra: "AD",
      Angola: "AO",
      "Antigua & Deps": "AG",
      Argentina: "AR",
      Armenia: "AM",
      Australia: "AU",
      Austria: "AT",
      Azerbaijan: "AZ",
      Bahamas: "BS",
      Bahrain: "BH",
      Bangladesh: "BD",
      Barbados: "BB",
      Belarus: "BY",
      Belgium: "BE",
      Belize: "BZ",
      Benin: "BJ",
      Bhutan: "BT",
      Bolivia: "BO",
      "Bosnia Herzegovina": "BA",
      Botswana: "BW",
      Brazil: "BR",
      Brunei: "BN",
      Bulgaria: "BG",
      Burkina: "BF",
      Burundi: "BI",
      Cambodia: "KH",
      Cameroon: "CM",
      Canada: "CA",
      "Cape Verde": "CV",
      "Central African Rep": "CF",
      Chad: "TD",
      Chile: "CL",
      China: "CN",
      Colombia: "CO",
      Comoros: "KM",
      Congo: "CG",
      "Congo {Democratic Rep}": "CD",
      "Costa Rica": "CR",
      Croatia: "HR",
      Cuba: "CU",
      Cyprus: "CY",
      "Czech Republic": "CZ",
      Denmark: "DK",
      Djibouti: "DJ",
      Dominica: "DM",
      "Dominican Republic": "DO",
      "East Timor": "TL",
      Ecuador: "EC",
      Egypt: "EG",
      "El Salvador": "SV",
      "Equatorial Guinea": "GQ",
      Eritrea: "ER",
      Estonia: "EE",
      Ethiopia: "ET",
      Fiji: "FJ",
      Finland: "FI",
      France: "FR",
      Gabon: "GA",
      Gambia: "GM",
      Georgia: "GE",
      Germany: "DE",
      Ghana: "GH",
      Greece: "GR",
      Grenada: "GD",
      Guatemala: "GT",
      Guinea: "GN",
      "Guinea-Bissau": "GW",
      Guyana: "GY",
      Haiti: "HT",
      Honduras: "HN",
      Hungary: "HU",
      Iceland: "IS",
      India: "IN",
      Indonesia: "ID",
      Iran: "IR",
      Iraq: "IQ",
      "Ireland {Republic}": "IE",
      Israel: "IL",
      Italy: "IT",
      "Ivory Coast": "CI",
      Jamaica: "JM",
      Japan: "JP",
      Jordan: "JO",
      Kazakhstan: "KZ",
      Kenya: "KE",
      Kiribati: "KI",
      "Korea North": "KP",
      "Korea South": "KR",
      Kosovo: "XK",
      Kuwait: "KW",
      Kyrgyzstan: "KG",
      Laos: "LA",
      Latvia: "LV",
      Lebanon: "LB",
      Lesotho: "LS",
      Liberia: "LR",
      Libya: "LY",
      Liechtenstein: "LI",
      Lithuania: "LT",
      Luxembourg: "LU",
      Macedonia: "MK",
      Madagascar: "MG",
      Malawi: "MW",
      Malaysia: "MY",
      Maldives: "MV",
      Mali: "ML",
      Malta: "MT",
      "Marshall Islands": "MH",
      Mauritania: "MR",
      Mauritius: "MU",
      Mexico: "MX",
      Micronesia: "FM",
      Moldova: "MD",
      Monaco: "MC",
      Mongolia: "MN",
      Montenegro: "ME",
      Morocco: "MA",
      Mozambique: "MZ",
      "Myanmar, {Burma}": "MM",
      Namibia: "NA",
      Nauru: "NR",
      Nepal: "NP",
      Netherlands: "NL",
      "New Zealand": "NZ",
      Nicaragua: "NI",
      Niger: "NE",
      Nigeria: "NG",
      Norway: "NO",
      Oman: "OM",
      Pakistan: "PK",
      Palau: "PW",
      Panama: "PA",
      "Papua New Guinea": "PG",
      Paraguay: "PY",
      Peru: "PE",
      Philippines: "PH",
      Poland: "PL",
      Portugal: "PT",
      Qatar: "QA",
      Romania: "RO",
      "Russian Federation": "RU",
      Rwanda: "RW",
      "St Kitts & Nevis": "KN",
      "St Lucia": "LC",
      "Saint Vincent & the Grenadines": "VC",
      Samoa: "WS",
      "San Marino": "SM",
      "Sao Tome & Principe": "ST",
      "Saudi Arabia": "SA",
      Senegal: "SN",
      Serbia: "RS",
      Seychelles: "SC",
      "Sierra Leone": "SL",
      Singapore: "SG",
      Slovakia: "SK",
      Slovenia: "SI",
      "Solomon Islands": "SB",
      Somalia: "SO",
      "South Africa": "ZA",
      "South Sudan": "SS",
      Spain: "ES",
      "Sri Lanka": "LK",
      Sudan: "SD",
      Suriname: "SR",
      Swaziland: "SZ",
      Sweden: "SE",
      Switzerland: "CH",
      Syria: "SY",
      Taiwan: "TW",
      Tajikistan: "TJ",
      Tanzania: "TZ",
      Thailand: "TH",
      Togo: "TG",
      Tonga: "TO",
      "Trinidad & Tobago": "TT",
      Tunisia: "TN",
      Turkey: "TR",
      Turkmenistan: "TM",
      Tuvalu: "TV",
      Uganda: "UG",
      Ukraine: "UA",
      "United Arab Emirates": "AE",
      "United Kingdom": "GB",
      "United States": "US",
      Uruguay: "UY",
      Uzbekistan: "UZ",
      Vanuatu: "VU",
      "Vatican City": "VA",
      Venezuela: "VE",
      Vietnam: "VN",
      Yemen: "YE",
      Zambia: "ZM",
      Zimbabwe: "ZW",
    };

    return countryCodes[countryName] || "Unknown";
  }

  const currencies = countries.map((country, index) => ({
    value: index.toString(),
    label: country,
  }));

  // Function to send email
  const handleSubmitForm = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_uubi856",
        "template_mwystka",
        form.current,
        "F3fCl9xjwtltwxCp7"
      )
      .then(
        (result) => {
          Swal.fire({
            icon: "success",
            title: "Message Sent!",
            text: "Your message has been received. We will get back to you soon.",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'custom-confirm-button-class',
            },
          });
          navigate("/");
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "An error occurred while sending your message. Please try again later.",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: 'custom-confirm-button-class',
            },
          });
        }
      );
  }


  return (
    <>
    <Helmet>
        <title>US Digital Marketing | Contact Us</title>
        <meta
          name="description"
          content="Enhance your online presence with UDM's story. We specialize in on-page SEO, keyword research, off-page SEO, technical SEO, local SEO, e-commerce SEO, competitor analysis, analytics, and reporting."
        />
      </Helmet>

      <Navbar />
      <motion.div className="talk-container">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div style={{ position: "relative" }}>
            {/* Contact Form */}
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                width: "70%",
                margin: "auto",
                display: "block",
                alignItems: "center",
                color: "black",
                position: "absolute",
                top: "100%",
                left: "0",
                right: "0",
              }}
            >
              <form className="my-5" ref={form} onSubmit={handleSubmitForm}>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Your name*
                    </label>
                    <div className="mb-3">
                      <TextField
                        required
                        id="name"
                        name="name"
                        variant="filled"
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Contact email*
                    </label>
                    <div className="mb-3">
                      <TextField
                        required
                        id="email"
                        name='email'
                        variant="filled"
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="company_name" className="form-label">
                      Company name*
                    </label>
                    <div className="mb-3">
                      <TextField
                        required
                        id="company_name"
                        name='company_name'
                        variant="filled"
                        fullWidth
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="country" className="form-label">
                      Country*
                    </label>
                    <div className="mb-3">
                      <TextField
                        id="country"
                        select
                        helperText="Please select your country"
                        variant="filled"
                        style={{ width: "100%" }}
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <ReactCountryFlag
                              countryCode={getCountryCode(option.label)}
                              svg
                              style={{ marginRight: "8px" }}
                            />
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>
                </div>
                <label htmlFor="message" className="form-label">
                  Your message*
                </label>

                <div className="mb-3">
                  <textarea
                    class="form-control"
                    placeholder="Type your message here...."
                    id="message"
                    name="message"
                  ></textarea>
                </div>

                <p style={{ color: "#5A7184" }}>
                  {" "}
                  By submitting this form you agree to our terms and conditions
                  and our Privacy Policy which explains how we may collect, use
                  and disclose your personal information including to third
                  parties.
                </p>
                <button
                  type="submit"
                  className="btn-5 talk-btn"
                  style={{ margin: "auto", background: "#0A3161", color: "white", display: "flex", justifyContent: "center" }}
                >
                  Contact
                </button>
              </form>
            </div>

            {/* Contact Header */}
            <header class="jumbotron my-5">
              <div
                style={{
                  margin: "auto",
                  display: "block",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <div class="row row-header">
                  <div
                    class="col-12 col-sm-6"
                    style={{
                      margin: "auto",
                      display: "block",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1>
                      <b style={{ color: "#B31942" }}>Contact</b>{" "}
                      <b style={{ color: "#0A3161" }}>Us</b>
                    </h1>

                    <p className="my-3">
                      If you need our help, have questions about how to use the
                      platform or are experiencing technical difficulties,
                      please do not hesitate to contact us.
                    </p>
                  </div>
                </div>
              </div>
            </header>
          </div>

          {/* Contact footer */}
          <motion.div className="contentFooter pb-5" >
            <>
              <div class="row">
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <div class="card bg-transparent">
                    <div class="card-body">
                      <div className="contact-bg">
                        <img src={email} className="my-3" alt='email' />
                      </div>
                      <h5 class="card-title my-3">Email us</h5>
                      <p class="card-text">
                        Email us for general queries, including marketing and
                        partnership opportunities.
                      </p>
                      <p className="contact-p">usdigitalmarketing.us@gmail.com</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 mb-3 mb-sm-0">
                  <div class="card bg-transparent">
                    <div class="card-body">
                      <div className="contact-bg">
                        <img src={call} className="my-3" alt='call'/>
                      </div>
                      <h5 class="card-title my-3">Call us</h5>
                      <p class="card-text">
                        Call us to speak to a member of our team. We are always
                        happy to help.
                      </p>
                      <p className="contact-p">+1(702) 296-7702</p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card bg-transparent">
                    <div class="card-body">
                      <div className="contact-bg">
                        <img src={support} className="my-3" alt='support'/>
                      </div>
                      <h5 class="card-title my-3">Support</h5>
                      <p class="card-text">
                        Contact us through form or checkout FAQs.
                      </p>
                      <button className="btn support-btn contact-p" onClick={() => {
                        navigate("/talk")
                      }}>
                        Support Center{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
}

export default Talk;
