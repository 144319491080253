import React from 'react';
import heroV from "../../../assets/img/UDM Marketing Video 01.mp4";

function VideoAbout() {
    return (
        // <div class="card video-card w-100">
        //     <div class="card-body">
        <div id="myvideo" className="video-container">
            <video autoPlay loop muted className="video-background">
                <source src={heroV} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Text Content */}
            <div className="video-content">
               
            </div>
        </div>
    );
}

export default VideoAbout;
