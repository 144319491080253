import React from "react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import Footer from "../Home/Section6/Footer";
import Navbar from "../Home/Section1/Navbar";
import Divider from "@mui/material/Divider";
import { AiFillStar } from "react-icons/ai";
import { Helmet } from "react-helmet";
import LetsFind from "../Home/Section7/LetsFind";

export default function DbProgramming() {
  return (
    <>
      <Helmet>
        <title>Advanced Database Programming Service | Secure Solutions!</title>
        <meta
          name="description"
          content="Looking for expert database programming services? Our team offers full support: design, modeling, SQL query development. Contact us now"
        />
      </Helmet>


      <Navbar />
      <div className="db-container">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div
            className=""
            style={{ background: "transparent", textAlign: "center" }}
          >
            <h1 className="hero-title">
              <b style={{ color: "#B31942" }}>Database</b>{" "}
              <b style={{ color: "#0A3161" }}>Programming</b>
            </h1>
          </div>
          <div className="container web-description">
            <p>
              At US Digital Marketing, our experienced team provides comprehensive database management and programming support for social media services. As a digital marketing agency, we offer a full range of database solutions to support your  digital marketing initiatives.
            </p>
            <Divider>
              <AiFillStar size={20} color="#da1441" alt= "us digital marketing star" />
            </Divider>
          </div>
          <br />

          <motion.div>
            <div class="row row-cols-1 row-cols-md-2 g-4 px-5">
              <div class="col">
                <div class="card"
                  style={{
                    borderLeftColor: "#B31942", borderRightColor: "transparent",
                    borderTopColor: "transparent",
                    borderLeftWidth: "4px"
                  }}
                >
                  <div class="card-body px-5">
                    <h2>
                      <b>Database Design</b>
                    </h2>
                    <p class="card-text">
                      Our expert database architects design optimized online database software tailored to your
                      specific business needs. We develop robust and scalable database structures built for best
                      database programming.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card"
                  style={{
                    borderBottomColor: "transparent",
                    borderRightColor: "transparent",
                    borderTopColor: "#B31942",
                    borderLeftColor: "#B31942",
                    borderTopWidth: "4px",
                    borderLeftWidth: "4px"
                  }}>
                  <div class="card-body px-5">
                    <h2>
                      <b>Database Security</b>
                    </h2>
                    <p class="card-text">
                      Database security is a top priority. We implement rigorous protections, access controls, and encryption to keep your data safe. Trust our digital database programming company to handle your database services and security.
                    </p>
                  </div>
                </div>
              </div>
             
              <div class="col">
                <div class="card"
                  style={{
                    borderRightColor: "#B31942", 
                    borderLeftColor: "transparent",
                    borderTopColor: "transparent",
                    borderRightWidth: "4px",
                  }}>
                  <div class="card-body px-5">
                    <h2>
                      <b>SQL / MongoDB Queries Development</b>
                    </h2>
                    <p class="card-text">
                      Our database programming expert write optimised SQL and NoSQL queries to access and manipulate your data securely. We ensure fast, reliable database queries that deliver the information you need.
                    </p>
                  </div>
                </div>
              </div>
             
              <div class="col">
                <div class="card"
                  style={{
                    borderBottomColor: "transparent",
                    borderLeftColor: "transparent",
                    borderTopColor: "#B31942",
                    borderRightColor: "#B31942",
                    borderTopWidth: "4px",
                    borderRightWidth: "4px"
                  }}>
                  <div class="card-body">
                    <div class="card-body px-5">
                      <h2>
                        <b>Database Modeling</b>
                      </h2>
                      <p class="card-text">
                        Our database certified professional perform advanced data modelling to structure your information for maximum efficiency. We analyze your unique requirements to build customized database models that organize your data.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </motion.div>

        </div>
      </div>

      <div className="db-container" style={{ marginTop: "120px" }}>
        <div
          style={{ background: "transparent", textAlign: "center" }}
        >
          <h2 className="hero-title">
            What Makes Us Different Than Others?
          </h2>
        </div>
        <div className="container web-description">
          <p>
            Data-driven strategies are key components of digital marketing success. Our  US digital marketing
            company excels in offering cutting-edge database programming services specifically designed to meet
            the unique requirements of businesses. From targeted ads and personalized campaigns, to advanced
            database technologies that facilitate actionable insights and extraordinary outcomes. We take
            great pride in the services that we provide businesses.

          </p>
          <Divider>
            <AiFillStar size={20} color="#da1441" alt= "us digital marketing star 2" />
          </Divider>
        </div>

        <div className="row">
          <div className="col">
            <div className="card-group">
              <div className="card client-card choose-scroll">
                <div className="choose__card">
                  <AiFillStar color="#da1441" alt= "us digital marketing star 3" />
                </div>
                <div className="card-body">
                  <p className="db-title">Tailored <br /> Services</p>
                  <p>Our Company Provide well customized database programming services specially design for target marketing enterprise.</p>
                </div>
              </div>
              <div className="card client-card choose-scroll my-5">
                <div className="choose__card">
                  <AiFillStar color="#da1441"  alt= "us digital marketing star 4"/>
                </div>
                <div className="card-body">
                  <p className="db-title">Proven <br /> Record</p>
                  <p>Our company have a  proven record in optimizing brands through our expert data programming services.

                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <Lottie
              animationData={require("../../assets/animation/db-prog.json")}
              loop
              autoplay
              className="img-fluid rounded-start lottie-animation"
            />
          </div>
          <div className="col">
            <div className="card-group">
              <div className="card client-card choose-scroll my-5">
                <div className="choose__card">
                  <AiFillStar color="#da1441" alt= "us digital marketing star 5" />
                </div>
                <div className="card-body">
                  <p className="db-title">Integration</p>
                  <p> Integrate our solutions seamlessly into leading marketing automation platforms for seamless workflow and increased productivity.</p>
                </div>
              </div>
              <div className="card client-card choose-scroll">
                <div className="choose__card">
                  <AiFillStar color="#da1441" alt= "us digital marketing star 6" />
                </div>
                <div className="card-body">
                  <p className="db-title">Data <br /> Security</p>
                  <p>We ensure your data is safe. We use strong security measures that follow all the rules in the industry. This way, we save all your essential data from the wrong people who want to steal it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="card-group">
              <div className="card client-card choose-scroll">
                <div className="choose__card">
                  <AiFillStar color="#da1441" alt= "us digital marketing star 7" />
                </div>
                <div className="card-body">
                  <p className="db-title">Utilizing Advanced <br /> Technologies</p>
                  <p> Choose our database programming expert in cutting-edge database<br /> technologies
                    to efficiently organize
                    and analyze your data.
                  </p>
                </div>
              </div>

              <div className="card client-card choose-scroll">
                <div className="choose__card">
                  <AiFillStar color="#da1441" alt= "us digital marketing star 8" />
                </div>
                <div className="card-body">
                  <p className="db-title">Client-Centric <br /> Approach</p>
                  <p>We emphasize understanding your unique requirements and goals and developing <br />
                    tailored solutions that align with them in line with your vision and objectives.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LetsFind />
      <Footer />
    </>
  );
}
