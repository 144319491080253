import React from "react";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import Footer from "../Home/Section6/Footer";
import Navbar from "../Home/Section1/Navbar";
import { Helmet } from "react-helmet";

const blogData = [
  {
    animationData: require("../../assets/animation/blog.json"),
    title: "Coming Soon",
  },
  {
    animationData: require("../../assets/animation/blog.json"),
    title: "Coming Soon",
  },
  {
    animationData: require("../../assets/animation/blog.json"),
    title: "Coming Soon",
  },
  {
    animationData: require("../../assets/animation/blog.json"),
    title: "Coming Soon",
  },
  {
    animationData: require("../../assets/animation/blog.json"),
    title: "Coming Soon",
  },
  {
    animationData: require("../../assets/animation/blog.json"),
    title: "Coming Soon",
  },
];

export default function OurBlogs() {
  return (
    <>
     <Helmet>
        <title>US Digital Marketing | Blogs</title>
        <meta
          name="description"
          content="Enhance your online presence with UDM's branding services. We specialize in on-page SEO, keyword research, off-page SEO, technical SEO, local SEO, e-commerce SEO, competitor analysis, analytics, and reporting."
        />
      </Helmet>

      <Navbar />
      <motion.div className="blog-container">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div
            className=""
            style={{ background: "transparent", textAlign: "center" }}
          >
            <h3 className="hero-title">
              <b style={{ color: "#0A3161" }}>Blog</b>
              <b style={{ color: "#B31942" }}>s</b>

            </h3>
          </div>
          <div className="row row-cols-1 row-cols-md-3 g-4 px-5 py-5 text-center">
            {blogData.map((blog, index) => (
              <div className="col" key={index}>
                <div className="card px-5 py-5">
                  <Lottie
                    animationData={blog.animationData}
                    loop
                    autoplay
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{blog.title}</h5>
                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
      <br />
      <br />
      <br />
      <Footer />
    </>
  );
}
