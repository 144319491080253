import React from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import Navbar from "../Home/Section1/Navbar";
import Footer from "../Home/Section6/Footer";
import LetsFind from "../Home/Section7/LetsFind";
import bg from "../../assets/img/mimi-thian-ZKBzlifgkgw-unsplash.jpg"

export default function Story() {
  return (
    <>
      <Helmet>
        <title>US Digital Marketing | Story</title>
        <meta
          name="description"
          content=" Using data-driven digital marketing to drive business and get results."
        />
      </Helmet>

      <Navbar />
      <motion.div className="story-background">
        <div className="screens-height" >
          <div className="story-first-container">
            <div className="story-first-content">
              <div className="card about-card w-100 mb-3 mt-4" style={{ borderTopColor: "transparent", borderBottomColor: "transparent" }}>
                <div className="row g-0">
                  <div className="col-md-12">
                    <p className="how-come-together" >
                      <span style={{ color: "#FFF" }}>Our</span>{" "}
                      <span style={{ color: "#FFF" }}>Approach</span>
                    </p>
                    <div
                      className="card-body mx-5"
                      style={{
                        padding: "20px 12px 100px 12px", color: "white", textAlign: "center",
                        fontSize: "18px"
                      }}
                    >
                      <div className="card-text">
                        Using data-driven digital marketing to drive business and get results.
                        Our approach to online marketing at us digital marketing is centered <br/> 
                        and localized on strategy, and execution. You'll get the best outcomes here.<br/> 
                        As a top digital marketing consultant, we support the brands their online growth by <br/> 
                        utilizing data, technology, and creativity.
                      </div>
                      <div className="card-text mt-3" >
                        <p style={{ fontWeight: "bold" }}>Formulating Tailored Approaches in Line with Business Intents</p>
                        <p>
                          Knowing your targets is the first step in any undertaking and processing.
                          Are you struggling to increase your website traffic?<br/>
                          Boost the lead generations and conversion? Louden the awareness of your brand?
                          Here you get the right one. <br/> 
                          Together, we establish success metrics and create a plan of action to help you reach them.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="story-first-background"></div>
          </div>

          <div className="about-strip-container mt-5">
            <marquee className="marq" bgcolor="transparent" direction="left" loop="">
              <span className="">Social Media Marketing</span>
              <span className="mx-5">Website Development</span>
              <span className="mx-5">Search Engine Optimization</span>
              <span className="mx-5">Content Writing</span>
              <span className="mx-5">Branding Strategy</span>
              <span className="mx-5">Database Programming</span>
            </marquee>
            <marquee className="marq mt-3" bgcolor="transparent" direction="right" loop="">
              <span className="">Social Media Marketing</span>
              <span className="mx-5">Website Development</span>
              <span className="mx-5">Search Engine Optimization</span>
              <span className="mx-5">Content Writing</span>
              <span className="mx-5">Branding Strategy</span>
              <span className="mx-5">Database Programming</span>
            </marquee>
          </div>

          <div className="card about-card w-100 mb-3 mt-4" style={{ borderTopColor: "transparent", borderBottomColor: "transparent" }}>
            <div className="row g-0">
              <div className="col-md-8">
                <div
                  className="card-body mx-3"
                  style={{ padding: "100px 12px 100px 12px" }}
                >
                  <div className="card-text mt-2" >
                    <p style={{ fontWeight: "bold" }}>Encouraging Measurable Results for Long-term Success</p>
                    <p>Not vanity metrics, but outcomes tailored to your KPIs are the focus of our advanced marketing efforts. We quantify everything and adjust for information experiences. You receive personalized reporting that monitors:
                    </p>
                    <ul>
                      <li>
                        Lead generations
                      </li>
                      <li>
                        Our goal is simple: use creative marketing strategies to deliver the current reality results your company needs.
                      </li>
                    </ul>

                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <p className="how-come-together"
                  style={{ padding: "100px 12px 100px 12px" }}>
                  Are you ready to improve your advanced advertising outcomes? for free advice,
                  <span style={{ color: "#B31942" }}> Contact</span>{" "}
                  <span style={{ color: "#0A3161" }}>us.</span>
                </p>
              </div>
            </div>
          </div>

          <div className="about-strip-container">
            <marquee className="marq" bgcolor="transparent" direction="left" loop="">
              <span className="">Social Media Marketing</span>
              <span className="mx-5">Website Development</span>
              <span className="mx-5">Search Engine Optimization</span>
              <span className="mx-5">Content Writing</span>
              <span className="mx-5">Branding Strategy</span>
              <span className="mx-5">Database Programming</span>
            </marquee>
            <marquee className="marq mt-3" bgcolor="transparent" direction="right" loop="">
              <span className="">Social Media Marketing</span>
              <span className="mx-5">Website Development</span>
              <span className="mx-5">Search Engine Optimization</span>
              <span className="mx-5">Content Writing</span>
              <span className="mx-5">Branding Strategy</span>
              <span className="mx-5">Database Programming</span>
            </marquee>
          </div>

          <div className="card about-card w-100 mb-3 mt-4" style={{ borderTopColor: "transparent", borderBottomColor: "transparent" }}>
            <div className="row g-0">
              <div className="col-md-4">
                <p className="how-come-together px-5"
                  style={{ padding: "100px 12px 100px 12px" }}>
                  <span style={{ color: "#B31942" }}>How we came <br /></span>{" "}
                  <span style={{ color: "#0A3161" }}>together</span>
                </p>
              </div>
              <div className="col-md-8">
                <div
                  className="card-body mx-3"
                >
                  <section className="section1">
                    <div id="portfolio-story">
                      <div id="project">
                        <div className="portfolio-story-content">
                          <div className="row-portfolio">
                            <img src={bg} alt="us digital marketing about"/>
                            <div className="layer-portfolio">
                              <div className="card-text mt-2" >
                                <p>
                                  To find the most effective and productive ways to connect with your target audience, our digital marketing experts research your sector, rivals, and ongoing marketing initiatives. To optimize content for search visibility, we manage thorough keyword research.
                                  We also evaluate the user experience on your website and pinpoint major areas for improvement to get an expansion of conversions.
                                </p>
                                <p>
                                  A thorough digital marketing plan that is unique to your company, goods, and client is the result.
                                </p>

                                <p style={{ fontWeight: "bold" }}>
                                  Managing Multi-Channel Campaigns Accurately
                                </p>
                                <p>
                                  After establishing a sound plan, we execute it tactically across important digital platforms. We can establish precise and accurate benchmarks and iterate campaigns according to performance thanks to our data-driven strategy.
                                </p>
                                <ul style={{ listStyle: "none" }}>
                                  <li>
                                    Search Engine Optimization (SEO)
                                  </li>
                                  <li>
                                    Pay-Per-Click (PPC)
                                  </li>
                                  <li>
                                    Virtual Entertainment</li>
                                  <li>
                                    Content Advertising </li>
                                  <li>
                                    Email marketing </li>
                                </ul>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <LetsFind />
      <Footer />
    </>
  );
}
