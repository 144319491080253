import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import Navbar from "../Home/Section1/Navbar";
import Footer from "../Home/Section6/Footer";
import Divider from "@mui/material/Divider";
import { AiFillStar } from "react-icons/ai";
import LetsFind from "../Home/Section7/LetsFind";


export default function ContentWriting() {
  const [selectedSummary, setSelectedSummary] = useState(null);

  const services = [
    "Topic Research.",
    "Keyword Research.",
    "Content Planning.",
    "Writing Style.",
    "Headlines and Titles.",
    "Introduction.",
    "Body Content.",
    "Grammar and Proofreading.",
    "Voice and Tone.",
    "Citing Sources.",
    "Engagement and Flow.",
    "Content Promotion.",
    "Quality Control.",
  ];
  const summaries = [
    "First, we do proper research on your industry. Audience and competitors, and then decide topics that boost engagement on your content and provide the best results.",
    "We find SEO-optimised words and add these keywords into content to rank content on search engine optimization. Trust us if you want to maximise your content for success.",
    "We create a content calendar according to your industry and future goals you want to achieve. And ensure they provide SEO content writing services.",
    "We use a style that matches your industry and ensures an authentic tone that attracts your audience.",
    "We use catchy headings and exciting titles that attract an audience toward your content and make people want to click on your content.",
    "We start with a hook introduction that is clear and interesting and gets ready for your target audience and other audiences for your content.",
    "We write your content's body structure with clarity and flow and ensure your readers enjoy it while reading your content.",
    "We deeply proofread every detail to ensure your content looks professional and error-free.",
    "We know the perfect balance between informative and engaging. We use a tone that reflects your brand's story and values.",
    "We use perfect content writing strategies to keep your readers engaged and ensure a smooth information flow.",
    "we provide practical guidance and tools to promote your content on different platforms.",
    "We use different quality control processes to guarantee results that meet your expectations."
  ];

  return (
    <>
      <Helmet>
        <title>Expert Content Writing Service in USA | Contact Now!</title>
        <meta
          name="description"
          content="Raise your brand with UDM's professional content writing services. From catchy articles to engaging  social media posts. We'll help you to connect."
        />
      </Helmet>

      <Navbar />
      <motion.div className="content-background">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div
            className=""
            style={{ background: "transparent", textAlign: "center" }}
          >
            <h1 className="hero-title">
              <b style={{ color: "#B31942" }}>Content</b>{" "}
              <b style={{ color: "#0A3161" }}>Writing</b>
            </h1>
          </div>
          <div className="container web-description">
            <p>
              if you're looking for the best content-writing services, look no further.
              US Digital Marketing is here for you. We provide the best SEO-optimized writing services and your partner
              in improving your brand story.
              Our content writing service is not about putting words on paper. We create engaging stories that
              will attract your target audience.
            </p>
            <h2 className="my-5">
              <b>
                Our best content writing agency is here for you. <br />
                Trust us to increase your brand story that connects with millions of audiences. <br />
                What Makes Our Content Unique?
              </b>
            </h2>
            <Divider>
              <AiFillStar size={20} color="#da1441"  alt= "us digital marketing star"/>
            </Divider>
          </div>
          <div className="container web-ul">
            <ul style={{ display: "grid" }}>
              {services.map((service, index) => (
                <li key={index} style={{ listStyle: "none" }}>
                  <details>
                    <summary
                      className="summary-cw"
                      style={{ outline: "none", fontWeight: selectedSummary === index ? "bold" : "normal" }}
                      onClick={() => setSelectedSummary(index)}
                    >
                      {service}
                    </summary>
                    <p>{summaries[index]}</p>
                  </details>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </motion.div>
      <LetsFind />
      <Footer />
    </>
  );
}
