import React from 'react'

function Top() {
    const email = "usdigitalmarketing.us@gmail.com";
    const phoneNumber = "+17022967702";

    const openWhatsApp = () => {
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    return (
        <>
            <div className="pre-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-sm-8 col-7">
                            <ul className="info">
                                <li><a href={`mailto:${email}`}><i className="fa fa-envelope"></i>{email}</a></li>
                                <li><a style={{ textDecoration: "underline", cursor: "pointer" }} onClick={openWhatsApp}>
                                    <i className="fa fa-phone"></i>+1(702) 296-7702</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-5">
                            <ul className="social-media">
                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li><a href={`mailto:${email}`}><i className="fa fa-envelope"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Top;
