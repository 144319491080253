import React, { useState } from "react";
import { motion } from "framer-motion";
import Navbar from "../Home/Section1/Navbar";
import Footer from "../Home/Section6/Footer";
import Divider from "@mui/material/Divider";
import { AiFillStar } from "react-icons/ai";
import { SiTaichigraphics } from "react-icons/si";
import { CgWebsite } from "react-icons/cg";
import { LuCode2 } from "react-icons/lu";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { Helmet } from "react-helmet";

export default function WebDev() {
  const [isFlip, setFlip] = useState(false);
  const [isFlip1, setFlip1] = useState(false);
  const onClickFlip = () => {
    setFlip(!isFlip);
  };
  const onClickFlip1 = () => {
    setFlip1(!isFlip1);
  };

  return (
    <>
      <Helmet>
        <title>Professional Website Development Service | Call Today!</title>
        <meta
          name="description"
          content="Transform your online business with UDM's website development service. From site creation to user-friendly designs, we're your digital home."
        />
      </Helmet>

      <Navbar />
      <motion.div className="web-container">
        <div className="screens-height" style={{ marginTop: "100px" }}>
          <div
            className=""
            style={{ background: "transparent", textAlign: "center" }}
          >
            <h1 className="hero-title">
              <b style={{ color: "#B31942" }}>Website</b>{" "}
              <b style={{ color: "#0A3161" }}>Development</b>
            </h1>
          </div>
          <div className="container web-description">
            <p>
              At US Digital Marketing, our experienced team can design and develop a website tailored to your business goals using modern approaches. As a digital marketing agency, we provide the right tools and talent to help your online presence grow. Our digital marketing services help you establish a strong online marketing presence.
            </p>
            <Divider>
              <AiFillStar size={20} color="#da1441" alt= "us digital marketing star" />
            </Divider>
          </div>
          <div className="container web-ul">
            {/* Section 1 */}
            <div className="card-section container-lg my-5">
              <div className="row justify-content-center">
                {/* First Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div
                      className={`custom-card ${isFlip && "flipped"}`}
                      onClick={() => {
                        onClickFlip();
                      }}
                    >
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <SiTaichigraphics
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <h2 className="text-center">
                            Design and User Experience (UX)
                          </h2>
                        </div>
                      </div>
                      <div className=" back-side">
                        <div className="card-body text-center mb-sm-3 mb-0 mt-5">
                          <p>
                            <SiTaichigraphics
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <p>
                            Our designers create products that deliver meaningful and engaging experiences for users. We focus on crafting intuitive digital interfaces optimised for usability. As a creative agency, we build websites focused on your audience's brand strategy.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Second Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div
                      className={`custom-card ${isFlip1 && "flipped"}`}
                      onClick={() => {
                        onClickFlip1();
                      }}
                    >
                      <div className=" front-side">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <CgWebsite
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <h2 className="text-center">Frontend Development</h2>
                        </div>
                      </div>
                      <div className=" back-side">
                        <div className="card-body text-center  mb-sm-3 mb-0 mt-5">
                          <p>
                            <CgWebsite
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <p>
                            Our talented front-end developers build responsive sites optimised for any device. We create customised designs based on your brand's unique needs. Our digital marketing consultants ensure your site aligns with your goals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Section 2 */}
            <div className="card-section container-lg my-5">
              <div className="row justify-content-center">
                {/* Third Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div
                      className={`custom-card ${isFlip && "flipped"}`}
                      onClick={() => {
                        onClickFlip();
                      }}
                    >
                      <div className="front-side d-flex flex-column align-items-center justify-content-center">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <LuCode2
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <h2 className="text-center">Backend Development</h2>
                        </div>
                      </div>
                      <div className=" back-side">
                        <div className="card-body text-center mb-sm-3 mb-0 mt-5">
                          <p>
                            <LuCode2
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <p>
                            Our backend developers ensure your site remains stable and secure. We handle critical behind-the-scenes functions to keep your website running smoothly. Our experts implement robust systems to support your digital marketing initiatives.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Fourth Card */}
                <div className="col-lg-5 col-md-6">
                  <div className="card-container">
                    <div
                      className={`custom-card ${isFlip1 && "flipped"}`}
                      onClick={() => {
                        onClickFlip1();
                      }}
                    >
                      <div className=" front-side">
                        <div className="card-body text-center pb-0 mt-5">
                          <p>
                            <BsFillRocketTakeoffFill
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <h2 className="text-center">Deployment and Launch</h2>
                        </div>
                      </div>
                      <div className=" back-side">
                        <div className="card-body text-center  mb-sm-3 mb-0 mt-5">
                          <p>
                            <BsFillRocketTakeoffFill
                              style={{ fontSize: "2rem" }}
                              color="floralwhite"
                            />
                          </p>
                          <p>
                            We seamlessly handle launching your new website, migrating content, and configuring domains. Our experts guide you through the entire process from start to finish. As a digital agency, we make launching your site simple.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <Footer />
    </>
  );
}
