import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import commas from "../../../assets/img/concept.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaUser } from "react-icons/fa";

export default function Client() {

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <motion.div className="client-container">
      <h2 className="hero-title large-text" style={{ marginTop: "120px" }}>
        What Our <span style={{ color: "#B31942" }}>Clients</span> Say About Us
        !
      </h2>
      <Slider {...settings}>
        <div class="card client-card client-scroll mt-5">
          <div class="steps__card-number"><FaUser alt="user feedback on us digital marketing" /></div>
          <div class="card-body">
            <p class="card-text">
              <img src={commas} className="commas" alt="us digital marketing commas1"/>Happy to Work with US Digital Marketing. By Availing of their
              Services, <br />We learned and applied new branding techniques to expand
              our Business. <br />In a Week, Our sales boomed by 20%.Thanks To US
              Digital Marketing Agency.Highly Recommended.
            </p>
            <p class="card-text">
              <small class="text-body-secondary">
                <i>Sophie Adams, Sales Director - UVW Electronics</i>
              </small>
            </p>
          </div>
        </div>
        <div
          class="card client-card client-scroll mt-5"
          style={{
            boxShadow: "0 20px 50px rgba(0, 0, 0, 0.5)",
            padding: "12px",
          }}
        >
          <div class="steps__card-number"><FaUser /></div>
          <div class="card-body">
            <img src={commas} className="commas" alt="us digital marketing commas1"/>
            <p class="card-text">
              Creative Mindset And Passion of US Digital Marketing Agency
              Attracts my attention every time.<br /> It understands our brand
              requirements, and we achieve better results.<br /> Thanks to US Digital
              Marketing.
            </p>
            <p class="card-text">
              <small class="text-body-secondary">
                <i>Linda Davis, Marketing Coordinator - WXY Services</i>
              </small>
            </p>
          </div>
        </div>
        <div class="card client-card client-scroll mt-5">
          <div class="steps__card-number"><FaUser /></div>
          <div class="card-body">
            <img src={commas} className="commas" alt="us digital marketing commas1"/>
            <p class="card-text">
              US Digital Marketing Agency is the Ideal Partner you are looking
              for in your Business. <br />Their timely cooperation and Business
              strategies in the Digital world have made our brand renowned among
              Customers.<br /> Try US Digital Marketing at least once.
            </p>
            <p class="card-text">
              <small class="text-body-secondary">
                <i>Mark Taylor, Founder - RST Innovations</i>
              </small>
            </p>
          </div>
        </div>
      </Slider>
    </motion.div>
  );
}
