import React from "react";
import { motion } from "framer-motion";
import { FaFacebookF } from "react-icons/fa";
import { BiLogoLinkedin } from "react-icons/bi";
import logo from "../../../assets/img/logostep.png";

export default function Footer() {
  function getCurrentYear() {
    return new Date().getFullYear();
  }

  return (
    <motion.div>
      <div id="footer" className="">
        <div className="bg-cds py-5">
          <div className="footer-grid">
            {/* Marketing Logo */}
            <div className="px-3 px-sm-3 px-md-0 px-lg-0 text-xl-center text-lg-center text-md-center text-start">
              <img src={logo} className="footer-logo" alt="logo" title="logo" />
            </div>

            {/* We Offer Section */}
            <div className="offer mt-4">
              <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>WE OFFER</h3>
              <div className="row">
                <div className="col">
                  <ul
                    className="navbar-nav"
                    style={{
                      listStyle: "unset",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <li className="nav-items">Social Media Marketing</li>
                    <li className="nav-items">Search Engine Optimization</li>
                    <li className="nav-items"> Website Development</li>
                  </ul>
                </div>
                <div className="col">
                  <ul
                    className="navbar-nav"
                    style={{
                      listStyle: "unset",
                      fontSize: "16px",
                      fontWeight: "normal",
                    }}
                  >
                    <li className="nav-items"> Database Programming</li>
                    <li className="nav-items">Content Writing</li>
                    <li className="nav-items">Branding Marketing</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Contact us Section */}
            <div className="contact mt-4">
              <h3 style={{ fontWeight: "bold", fontSize: "18px" }}>
                CONTACT US
              </h3>
              <ul
                className="navbar-nav"
                style={{ fontWeight: "normal", fontSize: "16px" }}
              >
                <li className="nav-items">Contact: +1(702) 296-7702</li>
                <li className="nav-items mt-3">
                  Email: usdigitalmarketing.us@gmail.com
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <style>
            {
              `
                            #section1 {
                                background: #92FE9D;
                            }
                    
                            #section2 {
                                background: #ff5e62;
                            }
                    
                            #section3 {
                                background: #182848;
                            }
                            #section4 {
                                background: #92FE9D;
                            }
                    
                            #section5 {
                                background: #ff5e62;
                            }
                    
                            #section6 {
                                background: #182848;
                            }.custom-shape-divider-top-1700550481 {
                                position: absolute;
                                
                                left: 0;
                                width: 100%;
                                overflow: hidden;
                                line-height: 0;
                            }
                            
                            .custom-shape-divider-top-1700550481 svg {
                                position: relative;
                                display: block;
                                width: calc(100% + 1.3px);
                                height: 135px;
                            }
                            
                            .custom-shape-divider-top-1700550481 .shape-fill {
                                fill: #FF5E62;
                            }`
            }

          </style>

          <section id="section2">
            <div class="custom-shape-divider-top-1700550481">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
              </svg>
            </div>
          </section>

          <section id="section3">
          </section>
        </div>

        {/* Copyright Section */}
        <div className="bg-footer d-flex flex-column gap-3 align-items-center justify-content-center">
          {/* <img src={wave} /> */}
          <div className="social-media d-flex gap-3 justify-content-center" style={{ zIndex: "2" }}>
            <a href="#" target="_blank">
              <FaFacebookF className="social-icon" alt="facebook icon"/>
            </a>
            <a href="#" target="_blank">
              <BiLogoLinkedin className="social-icon" alt="linkedin icon"/>
            </a>
          </div>
          <div className="copy-right" style={{ zIndex: "2" }}>©{getCurrentYear()} by US Digital Marketing</div>
        </div>

      </div>
    </motion.div>
  );
}
