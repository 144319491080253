import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [stickyNav, setstickyNav] = useState(false);
  const [toTop, settoTop] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [nav, setNav] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <AuthContext.Provider
      value={{
        modalOpen, setModalOpen, toggleModal,
        stickyNav,
        setstickyNav,
        toTop,
        settoTop,
        activeItem,
        setActiveItem,
        nav,
        setNav,
        scrolled,
        scrollToTop
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserLogin = () => useContext(AuthContext);

export default AuthProvider;
